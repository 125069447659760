import React, { useState, useEffect } from "react";
import "./home.css";
import { collection, addDoc } from "firebase/firestore"; // Importăm Firestore
import { db } from "./firebase";

// Importă imaginile pentru slideshow
import HomeImage1 from "./assets/home1.webp";
import HomeImage2 from "./assets/home2.webp";
import HomeImage3 from "./assets/home3.webp";
import HomeImage4 from "./assets/home4.webp";
import HomeImage5 from "./assets/home5.webp";
import HomeImage6 from "./assets/home6.webp";
import HomeImage9 from "./assets/home9.webp";
import HomeImage10 from "./assets/home10.webp";

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Stare pentru imaginea curentă
  const [name, setName] = useState(""); // Stare pentru nume
  const [email, setEmail] = useState(""); // Stare pentru email
  const [message, setMessage] = useState(""); // Stare pentru mesajul de confirmare/eroare

  const images = [
    HomeImage1,
    HomeImage2,
    HomeImage3,
    HomeImage4,
    HomeImage5,
    HomeImage6,
    HomeImage9,
    HomeImage10,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  // Funcție pentru a trimite numele și email-ul în Firestore
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "" || email === "") {
      setMessage("Te rugăm să introduci un nume și o adresă de email.");
      return;
    }

    try {
      await addDoc(collection(db, "emails"), {
        name: name, // Salvăm numele
        email: email,
        timestamp: new Date(), // Salvăm și timestamp-ul
      });
      setMessage("Numele și adresa ta de email au fost adăugate cu succes!");
      setName(""); // Resetăm câmpul de nume
      setEmail(""); // Resetăm câmpul de email
    } catch (error) {
      setMessage("A apărut o eroare. Încearcă din nou.");
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="home-container">
      <div className="home-left-side">
        <h1 className="home-title">Dă valoare timpului tău liber!</h1>
        <h2 className="home-subtitle">
          Simplifică-ți viața găsind ajutorul potrivit pentru orice task.
          Economisește timp și energie cu doar câteva clicuri.
        </h2>

        {/* Formularul pentru colectarea numelui și email-urilor */}
        <div className="email-collector">
          <h3 className="email-collector-title">
            Vrei să fii primul care află de lansarea Aidly.ro?
          </h3>
          <form className="form-container" onSubmit={handleSubmit}>
            <input
              className="input-name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)} // Actualizează starea numelui
              placeholder="Introdu numele tău"
              required
            />
            <input
              className="input-email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Actualizează starea emailului
              placeholder="Introdu adresa ta de email"
              required
            />
            <button type="submit" className="button-submit">
              Trimite
            </button>
          </form>
          {message && <p className="form-message">{message}</p>}
        </div>
        <div className="student-badge">
          Ești student? Profită de un an gratuit.
          <a href="mailto:contact@Aidly.ro?subject=Ajută-mă să încep! Sunt student și vreau să fac taskuri.">
            Contactează-ne!
          </a>
        </div>
      </div>

      {/* Slideshow în dreapta */}
      <div className="home-right-side">
        <img
          className="image-right"
          src={images[currentImageIndex]} // Afișăm imaginea curentă
          alt="Aidly.ro ajuta pe cine are nevoie in schimb de bani"
        />
      </div>
    </div>
  );
};

export default Home;
