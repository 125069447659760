import React from "react";
import "./navbar.css";
import Logo from "./assets/logo.webp";

const Navbar = () => {
  return (
    <div className="navbar-container">
      <div className="logo-container">
        <img className="logo-image" src={Logo} alt="Aidly" />
      </div>
    </div>
  );
};

export default Navbar;
